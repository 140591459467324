import React, { useEffect, useState } from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import sentenceCase from "../../utils/utilities";
import { FaCheck, FaRegClock } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import './css/OrderHistory.css';
import Slider from "react-slick";
import moment from "moment";
import { devApi } from "../../utils/constants";
import { toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import { IoCalendarClearOutline } from "react-icons/io5";
import { FiDownloadCloud } from "react-icons/fi";
import { RiArrowGoBackLine } from "react-icons/ri";

export default function OrderHistory({ searchQuery }) {
  const [orderDetails, setOrderDetails] = useState();
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [viewMoreOrder, setViewMoreOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);
  // const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
    ],
  };

  // Fetch orders
  useEffect(() => {
    const getOrderHistory = async () => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-type": "application/json",
        },
      };
      try {
        const response = await axios.post(`${devApi}/api/order/showOrdersApi`, null, config);
        // console.log("Fetched Orders:", response.data.orders);
        setOrderDetails(response.data.orders);
        setFilteredOrders(response.data.orders);
      } catch (error) {
        if (error.response && error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      }
    };

    getOrderHistory();
  }, [token, navigate]);

  // Filter orders based on search query
  useEffect(() => {
    if ((searchQuery || "").trim() === "") {
      // console.log("Search Query Empty. Showing All Orders.");
      setFilteredOrders(orderDetails);
    } else {
      // console.log("Filtering Orders by Search Query:", searchQuery);
      const filtered = orderDetails.filter((order) =>
        (order.event.title || "").toLowerCase().includes(searchQuery.toLowerCase())
      );
      // console.log("Filtered Orders:", filtered);
      setFilteredOrders(filtered);
    }
  }, [searchQuery, orderDetails]);

  // console.log("Search Query:", searchQuery);
  // console.log("Filtered Orders:", filteredOrders);

  const handleViewMore = (orderObject) => {
    setViewMoreOrder(orderObject);
    setShowModal(true);
  };

  const handleCloseViewMore = () => {
    setViewMoreOrder();
    setShowModal(false);
  };

  // console.log(searchQuery);

  // const handleDownloadTicket = (order) => {
  //   // using Java Script method to get PDF file
  //   const headers = {
  //     Authorization: "Bearer " + token,
  //     "Content-type": "application/json",
  //   };
  //   if (order) {
  //     fetch(order.ticketfullpath, {
  //       method: "GET",
  //       headers: headers,
  //       //body: JSON.stringify(body),
  //     }).then((response) => {
  //       response.blob().then((blob) => {
  //         // Creating new object of PDF file
  //         const fileURL = window.URL.createObjectURL(blob);

  //         // Setting various property values
  //         let alink = document.createElement("a");
  //         alink.href = fileURL;
  //         alink.download = `${order.event.title}_${order.first_name}_${order.order_reference}`;
  //         alink.click();
  //       });
  //     });
  //   }
  // };

  if (!orderDetails) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        {filteredOrders.length > 0 ? (
          <div className="row d-flex align-items-center">
            {filteredOrders
              .sort((a, b) => new Date(b.system_datetime) - new Date(a.system_datetime))
              .map((order, index) => {
                return (
                  <div
                    className="col-12 col-md-6 col-lg-4 gx-3 gy-3 gx-md-3 gy-md-5 gx-lg-4 gy-lg-3 gx-sm-0"
                    onClick={() => handleViewMore(order)}
                    style={{ cursor: "pointer" }}
                    key={order.id || index}
                  >
                    <div className="position-relative new-card-design p-3 border shadow-sm rounded-12 border-0 h-100 d-flex flex-column">
                      <div className="text-decoration-none h-100 d-flex flex-column">
                        {/* Event Image */}
                        <div
                          className="w-100 position-relative"
                          style={{
                            paddingTop: "56.25%",
                            borderRadius: "12px",
                            overflow: "hidden",
                          }}
                        >

                          <div
                            className="position-absolute top-0 start-0 w-100 h-100 banner-bg"
                            style={{
                              backgroundImage: `url(${order.event_ext.event_image_fullpath || ""})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              filter: order.event_ext.aspect_ratio !== "16:9" ? "blur(3px)" : "none",
                              borderRadius: "12px",
                            }}
                          >
                          </div>
                          <div
                            className="position-absolute top-0 start-0 w-100 h-100"
                            style={{
                              borderRadius: "10px",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={order.event_ext.event_image_fullpath || ""}
                              alt="Event-img"
                              loading="lazy"
                              className="banner-img position-absolute top-0 start-0 w-100 h-100"
                              style={{
                                objectFit: order.event_ext.aspect_ratio !== "16:9" ? "contain" : "cover",
                                borderRadius: "10px",
                              }}
                            />
                          </div>
                        </div>

                        {/* Event Details */}
                        <div className="mt-3 flex-grow-1">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="fs-5 fw-semibold mb-2 banner-title text-truncate">
                              {order.event.title || "Untitled Event"}
                            </div>
                            {/* Status Badge */}
                            <span
                              className={`badge mb-2 ${order.order_status_id === 1 ? "paid" : "refund"
                                }`}
                              style={{ color: "white" }}
                            >
                              {order.is_refunded || order.is_partially_refunded ? (
                                <div className="d-flex align-items-center gap-1 default-tag tag-refund">
                                  <RiArrowGoBackLine />
                                  <span>Refunded</span>
                                </div>
                              ) : order.event.event_cancel === 0 &&
                                moment(order.event.end_date).isBefore(moment()) &&
                                order.event.event_paused === 0 ? (
                                <div className="d-flex align-items-center gap-1 default-tag tag-refund">
                                  <FaCheck />
                                  <span>Completed</span>
                                </div>
                              ) : order.is_cancelled ? (
                                <div className="d-flex align-items-center gap-1 default-tag tag-cancel">
                                  <RxCross2 />
                                  <span>Canceled</span>
                                </div>
                              ) : null}
                            </span>
                          </div>

                          {/* Event Date and Time */}
                          <div className="d-flex align-items-center mb-2">
                            <IoCalendarClearOutline
                              className="flex-shrink-0 icon-color"
                              size={17}
                            />
                            <span className="ms-1 banner-date-day">
                              {moment(order.event.start_date).format("DD MMMM YYYY")}
                            </span>
                            <FaRegClock
                              className="ms-2 flex-shrink-0 icon-color"
                              size={16}
                            />
                            <span className="banner-date-day ms-1">
                              {moment(order.event.start_date).format("hh:mm A")}
                            </span>
                          </div>

                          <div className="location-desc d-flex align-items-start my-2">
                            <MdOutlineLocationOn className="flex-shrink-0 me-1 my-auto" size={18} />
                            <div className="flex-grow-1 text-truncate">
                              {order?.event.venue_name && `${order?.event.venue_name}`}
                              {order?.event.location_address_line_1 &&
                                `, ${order?.event.location_address_line_1}`}
                              {(order?.event.location_address_line_2 || order?.event.location_street_number) &&
                                `, ${(order?.event.location_address_line_2 || order?.event.location_street_number)}`}
                              {order?.event.location_address_line2 &&
                                `, ${order?.event.location_address_line2}`}
                              {order?.event.location_post_code &&
                                `, ${order?.event.location_post_code}`}
                              {order?.event.location_state &&
                                `, ${order?.event.location_state}`}
                              {order?.event.location_country &&
                                `, ${order?.event.location_country}`}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* View More Button */}
                      <div className="d-flex justify-content-end mt-3">
                        <h6 className="ticket-more-btn" >
                          View More
                        </h6>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="d-flex justify-content-center text-center align-items-center no-ticket">
                <h4 className="text-center no-result">
                  {searchQuery ? `No exact matches found for "${searchQuery}".` : "You have not booked any tickets!"}
                </h4>
              </div>
            </div>
          </div>
        )}
      </div>

      {
        viewMoreOrder ? (
          <Modal
            show={showModal}
            onHide={() => handleCloseViewMore()}
            className="overflow-auto custom-scrollbar"
            centered
            size="md"
            dialogClassName="my-modal"
          >
            <Modal.Body
              className="new-bg h-auto custom-scrollbar"
              style={{
                boxShadow: "0px 8px 8px -4px #10182808",
                borderRadius: "12px",
                overflowY: "auto",
                height: "100vh",
                maxHeight: "calc(100vh - 60px)",
              }}
            >
              <div className="order-header d-flex justify-content-between align-items-center px-4 py-2">
                <div className="order-details">Order Details</div>
                <button aria-label="Close" className="border-0 bg-none" onClick={() => setShowModal(false)}>
                  <RxCross2 size={20} style={{ color: "#98A2B3", marginBottom: "5px" }} />
                </button>
              </div>

              <div className="ticket-desc-body mx-3">
                <div className="event-order-box pb-0">
                  <h5 className="confirmed-h5 mb-3 text-center">Booking Confirmed</h5>
                  <div className="under-ticket d-flex flex-column flex-md-row justify-content-around text-center">
                    <div className="under-ticket1">
                      <h6>{viewMoreOrder.event.title}</h6>
                      <div className="booking-data row gap-3 justify-content-center p-0">
                        <div className="col-auto d-flex gap-1 align-items-center p-0">
                          <IoCalendarClearOutline size={14} />
                          <span style={{ fontSize: "12px", lineHeight: "18px" }}>
                            {moment(viewMoreOrder.event.start_date).format("DD MMMM YYYY")}
                          </span>
                        </div>
                        <div className="col-auto d-flex gap-1 align-items-center p-0">
                          <FaRegClock size={14} />
                          <span style={{ fontSize: "12px", lineHeight: "18px" }}>
                            {moment(viewMoreOrder.event.start_date).format("hh:mm A")}
                          </span>
                        </div>
                      </div>
                      <div className="booking-data row justify-content-center my-1">
                        <div className="col-12 col-md-auto text-center">
                          <MdOutlineLocationOn size={18} className="flex-shrink-0" />
                          <span className="" style={{ fontSize: "12px", lineHeight: "18px" }}>
                            {viewMoreOrder.event.venue_name && `${viewMoreOrder.event.venue_name}`}
                            {viewMoreOrder.event.location_address_line_1 &&
                              `, ${viewMoreOrder.event.location_address_line_1}`}
                            {(viewMoreOrder.event.location_address_line_2 || viewMoreOrder.event.location_street_number) &&
                              `, ${(viewMoreOrder.event.location_address_line_2 || viewMoreOrder.event.location_street_number)}`}
                            {viewMoreOrder.event.location_address_line2 &&
                              `, ${viewMoreOrder.event.location_address_line2}`}
                            {viewMoreOrder.event.location_state &&
                              `, ${viewMoreOrder.event.location_state}`}
                            {viewMoreOrder.event.location_country &&
                              `, ${viewMoreOrder.event.location_country}`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="booking-details my-2">
                  <div className="event-order-box">
                    <div className="d-flex justify-content-between">
                      <div className="order-data m-0">
                        <h5>Booking Date & Time: </h5>
                        <h6 className="m-0">
                          {moment(viewMoreOrder?.system_datetime).format(
                            "DD MMM YYYY, HH:mm:ss"
                          )}
                        </h6>
                      </div>
                      <div className="order-data m-0">
                        <h5>Booking ID:</h5>
                        <h6 className="m-0">
                          {viewMoreOrder?.order_reference}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="event-order-box">
                  {viewMoreOrder.order_items?.map((ticketType, index) => {
                    // Find the matching ticket in the tickets array
                    const matchingTicket = viewMoreOrder.tickets?.find(
                      (ticket) => ticket.id === ticketType.ticket_id
                    );

                    return (
                      <div key={index}>
                        <div className="d-flex justify-content-between align-items-center ticketcharge flex-wrap position-relative">
                          <div className="d-flex justify-content-between align-items-center w-100 m-0">
                            <div className="d-flex flex-column gap-1">
                              <div className="order-title">{ticketType.title}</div>
                              {matchingTicket?.description && (
                                <div className="order-description">{matchingTicket.description}</div>
                              )}
                            </div>
                            <div className="d-flex align-items-center flex-column">
                              <div className="d-flex gap-2">
                                <p className="m-0 paid-amount">
                                  ${" "}
                                  {viewMoreOrder.discount &&
                                    parseFloat(viewMoreOrder.discount) > 0 &&
                                    viewMoreOrder.coupondata &&
                                    viewMoreOrder.coupondata.ticket_id === ticketType.ticket_id
                                    ? (
                                      parseFloat(ticketType.unit_price) * parseInt(ticketType.quantity, 10) -
                                      parseFloat(viewMoreOrder.discount)
                                    ).toFixed(2)
                                    : (ticketType.unit_price * ticketType.quantity).toFixed(2)}
                                </p>
                                <div className="d-flex justify-content-center align-items-center order-quantity">
                                  {ticketType.quantity}
                                </div>
                              </div>
                              {viewMoreOrder.discount &&
                                parseFloat(viewMoreOrder.discount) > 0 &&
                                viewMoreOrder.coupondata &&
                                viewMoreOrder.coupondata.ticket_id === ticketType.ticket_id && (
                                  <p className="m-0 real-price">
                                    $ {(ticketType.unit_price * ticketType.quantity).toFixed(2)}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="px-3 mt-1">
                          {viewMoreOrder.discount &&
                            parseFloat(viewMoreOrder.discount) > 0 &&
                            viewMoreOrder.coupondata &&
                            viewMoreOrder.coupondata.ticket_id === ticketType.ticket_id && (
                              <div className="discount-message">Coupon applied</div>
                            )}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="booking-details my-2">
                  <div className="d-flex justify-content-between text-center text-md-start">
                    <div className="bookingId d-flex flex-column gap-2">
                      <div>Ticket ID:</div>
                      <div className="order-no">{viewMoreOrder.order_reference}</div>
                    </div>
                    <div className="bookingId">
                      <div>QR Code:</div>
                      <div className="order-no my-2">({sliderIndex + 1}/{viewMoreOrder.ticketsQRCode.length})</div>
                    </div>
                  </div>

                  <div className="qrcode-box d-flex justify-content-center align-items-center my-2">
                    <div className="w-100 slide-main-box">
                      <Slider {...settings} beforeChange={(current, next) => setSliderIndex(next)}>
                        {viewMoreOrder.ticketsQRCode.map((image, index) => (
                          <div key={index} className="d-flex justify-content-center align-items-center qr-show">
                            <img
                              id={index}
                              src={image}
                              alt={`QR Code ${index + 1}`}
                              className="img-fluid my-2"
                              style={{ borderRadius: "20px" }}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>

                <div className="contact-history text-center">
                  If you have any questions, please email us at {" "}
                  <Link to="mailto:info@eventgem.com" className="mail-id">info@eventgem.com</Link> {" "}
                  or reach out to the organizer {" "}
                  {viewMoreOrder.organizer.contact_name} at {viewMoreOrder.organizer.phone}
                </div>
              </div>

              <div className="d-flex gap-3 justify-content-end align-items-center my-4 mb-3">
                <button aria-label="Close" className="close-modal" onClick={() => setShowModal(false)}>
                  Close
                </button>
                <Link to={viewMoreOrder.ticketfullpath} target="_blank" rel="noreferrer">
                  <button aria-label="Download Ticket" className="ticket-download-btn me-3">
                    <FiDownloadCloud size={22} className="me-3" />
                    Download Ticket
                  </button>
                </Link>
              </div>
            </Modal.Body>
          </Modal >
        ) : (
          <></>
        )}
    </>
  );
}