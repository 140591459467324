import React, { useEffect, useState } from "react";
import "./Payments.css"
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
// import sentenceCase from "../../utils/utilities";
import { MdOutlineLocationOn } from "react-icons/md";
// import Qrcode from "../../Assets/Qrcode.png";
import moment from "moment";
import { toast } from "react-toastify";
import { devApi } from "../../utils/constants";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoCalendarClearOutline } from "react-icons/io5";
import { FiDownloadCloud } from "react-icons/fi";
import { FaRegClock } from "react-icons/fa6";
import animationData from "../../Assets/Animation - 1738132447154.json";
import Lottie from "lottie-react";

export default function PaymentSuccess() {
  const [sessionId, setSessionId] = useState();
  const [orderDetails, setOrderDetails] = useState();
  const [showAnimation, setShowAnimation] = useState(true);
  const token = useSelector((state) => state.user.token);
  // const isDupage = useSelector((state) => state.user.dupageCounty);
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    const timer = setTimeout(() => {
      setShowAnimation(false);
    }, 10000);

    setSessionId(sessionId);
    const getOrderDetails = async () => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-type": "application/json",
          Accept: "application/json",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const body = {
        session_id: sessionId,
        sys_datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
        order_type: state ? state.orderType : 0,
      };
      await axios
        .post(`${devApi}/api/e/paymentSucessGuest`, body, config)
        .then((response) => {
          setOrderDetails(response.data);
          // console.log(response.data);
        })
        .catch((error) => {
          if (error.response.status === 450) {
            toast.error("Session Expired, Please log-in again");
            navigate("/login");
          }
        });
    };
    getOrderDetails();
    return () => clearTimeout(timer);
  }, [navigate, state, token]);

  if (!orderDetails) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  return (
    <div className="new-bg">
      {orderDetails.customer ? (
        <div className="container">
          {showAnimation && (
            <div
              className="animation-container d-flex align-items-center justify-content-center"
              style={{ position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)", zIndex: 10 }}
            >
              <Lottie animationData={animationData} loop={false} />
            </div>
          )}
          <div className="container pt-4 mb-3 d-flex align-items-center">
            {/* Back Button */}
            <div className="d-flex align-items first-line-links-center justify-content-center">
              <button aria-label="Back to events" className="d-flex back-to-event align-items-center gap-2 border-0 bg-transparent" onClick={() => { navigate("/") }}>
                <IoMdArrowRoundBack size={22} className="first-line-icon" />
                <span className="d-flex">Back to Events</span>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="d-flex flex-column justify-content-center align-items-center gap-2 mt-4">
              <div className="title-payment text-center">
                You have successfully booked your tickets!
              </div>
              <p className="success-para text-center">
                Your event tickets with a QR code will be sent to {" "}
                <strong>{orderDetails.customer.email} </strong>
              </p>
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 payment-box mb-4 p-3 confirm-booking border-0 rounded-bg">
                <div className="event-order-box pb-0">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5 className="confirmed-h5 mb-2 mt-3 text-center w-100">
                      Booking Confirmed
                    </h5>
                  </div>

                  <div className="under-ticket d-flex w-100">
                    {/* <img
                      src={
                        orderDetails?.orders[0].event_ext.event_image_fullpath
                          && orderDetails.orders[0].event_ext
                            .event_image_fullpath
                      }
                      alt=""
                      className="payment-img"
                      style={{ borderRadius: "10px" }}
                    /> */}
                    <div className="under-ticket1 text-center my-2 w-100">
                      <h6>{orderDetails.orders[0].event.title}</h6>
                      <div className="d-flex flex-column gap-2">
                        {/* Date and Time Section */}
                        <div className="booking-data row gap-2 justify-content-center">
                          <div className="col-auto d-flex gap-1 align-items-center">
                            <IoCalendarClearOutline size={14} />
                            <span
                              style={{ fontSize: "12px", lineHeight: "18px" }}
                            >
                              {orderDetails.orders[0].event.startday}
                            </span>
                          </div>
                          <div className="col-auto d-flex gap-1 align-items-center">
                            <FaRegClock size={14} />
                            <span
                              style={{ fontSize: "12px", lineHeight: "18px" }}
                            >
                              {orderDetails.orders[0].event.starttime}
                            </span>
                          </div>
                        </div>

                        {/* Location Section */}
                        <div className="booking-data row justify-content-center my-1">
                          <div
                            className="col-12 col-md-auto d-flex gap-1 align-items-center justify-content-center text-center w-75"
                          >
                            <MdOutlineLocationOn size={18} className="flex-shrink-0" />
                            <span
                              className="text-wrap"
                              style={{ fontSize: "12px", lineHeight: "18px" }}
                            >
                              {orderDetails.orders[0].event.venue_name},{" "}
                              {orderDetails.orders[0].event.location_address_line_1},{" "}
                              {orderDetails.orders[0].event.location_address_line_2 || orderDetails.orders[0].event.location_street_number},{" "}
                              {orderDetails.orders[0].event.location_address_line2},{" "}
                              {orderDetails.orders[0].event.location_post_code}
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* Booking Details */}
                <div className="booking-details my-2">
                  <div className="event-order-box">
                    <div className="d-flex justify-content-between">
                      <div className="order-data m-0">
                        <h5>Booking Date & Time: </h5>
                        <h6 className="m-0">
                          <strong>
                            {moment(orderDetails.orders[0].system_datetime).format(
                              // "ddd DD, MMMM YYYY hh:mm A"
                              "DD MMM YYYY, HH:mm:ss"
                            )}
                          </strong>
                        </h6>
                      </div>
                      <div className="order-data m-0">
                        <h5>Booking ID:</h5>
                        <h6 className="m-0">
                          <strong>
                            {orderDetails.orders[0].order_reference}
                          </strong>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Ticket Details */}
                <div className="event-order-box">
                  {orderDetails.orders[0].order_items?.map((ticketType, index) => {
                    // Find the matching ticket in the tickets array
                    const matchingTicket = orderDetails.orders[0].tickets.find(
                      (ticket) => ticket.id === ticketType.ticket_id
                    );

                    return (
                      <div key={index}>
                        <div className="d-flex justify-content-between align-items-center ticketcharge position-relative">
                          <div className="d-flex justify-content-between align-items-center w-100 m-0">
                            <div className="d-flex flex-column gap-1">
                              <div className="order-title">{ticketType.title}</div>
                              {matchingTicket?.description && (
                                <div className="order-description">{matchingTicket.description}</div>
                              )}
                            </div>
                            <div className="d-flex align-items-center flex-column">
                              <div className="d-flex gap-2">
                                <p className="m-0 paid-amount">
                                  ${" "}
                                  {orderDetails.orders[0].discount &&
                                    parseFloat(orderDetails.orders[0].discount) > 0 &&
                                    orderDetails.orders[0].coupondata &&
                                    orderDetails.orders[0].coupondata.ticket_id === ticketType.ticket_id
                                    ? (
                                      parseFloat(ticketType.unit_price) * parseInt(ticketType.quantity, 10) -
                                      parseFloat(orderDetails.orders[0].discount || "0")
                                    ).toFixed(2)
                                    : (ticketType.unit_price * ticketType.quantity).toFixed(2)}
                                </p>
                                <div className="d-flex justify-content-center align-items-center order-quantity">
                                  {ticketType.quantity}
                                </div>
                              </div>
                              {orderDetails.orders[0].discount &&
                                parseFloat(orderDetails.orders[0].discount) > 0 &&
                                orderDetails.orders[0].coupondata &&
                                orderDetails.orders[0].coupondata.ticket_id === ticketType.ticket_id && (
                                  <p className="m-0 real-price">
                                    $ {(ticketType.unit_price * ticketType.quantity).toFixed(2)}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="px-3 mt-1">
                          {orderDetails.orders[0].discount &&
                            parseFloat(orderDetails.orders[0].discount) > 0 &&
                            orderDetails.orders[0].coupondata &&
                            orderDetails.orders[0].coupondata.ticket_id === ticketType.ticket_id && (
                              <div className="discount-message">
                                Coupon applied
                              </div>
                            )}
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className="d-flex justify-content-between align-items-center processcharge">
                    <p>
                      Processing fee
                      <small className="text-primary ms-1">
                        (non-refundable)
                      </small>
                    </p>

                    <p>
                      $
                      {parseFloat(
                        orderDetails.orders[0].amountdetails.processing_fee
                      ).toFixed(2)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center processcharge ">
                    <p>
                      Platform fee
                      <small className="text-primary ms-1">
                        (non-refundable)
                      </small>
                    </p>

                    <p>
                      {" "}
                      $
                      {parseFloat(
                        orderDetails.orders[0].amountdetails.platform_fee
                      ).toFixed(2)}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between align-items-center processcharge mt-2">
                    <small className="amountpay-sucess ">Total amount</small>
                    <small className="amountpay-sucess ">
                      ${" "}
                      {orderDetails.orders[0].amountdetails.full_total.toFixed(
                        2
                      )}
                    </small>
                  </div> */}
                </div>

                {/* Download Ticket */}
                <div className="d-flex justify-content-center align-items-center mb-4 mt-4">
                  <Link
                    to={orderDetails.orders[0].ticketfullpath || orderDetails.orders[0].ticket_pdf_path}
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none w-100"
                  >
                    <button aria-label="Download Ticket" className="d-flex align-items-center get-started justify-content-center w-100">
                      {" "}
                      <FiDownloadCloud size={22} className="me-2" />
                      Download Ticket
                    </button>
                  </Link>
                </div>
                <div className="qrcode-box text-center my-2">
                  <h6>
                    If you have any questions, please email us at {" "}
                    <Link to="mailto:info@eventgem.com" className="mail-id">info@eventgem.com</Link> {" "}
                    or reach out to the organizer {orderDetails.orders[0].organizer.contact_name} at {orderDetails.orders[0].organizer.phone}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center ndf">
          <div className="loader-box">
            <span></span>
            <span></span>
          </div>
        </div>
      )}
    </div>
  );
}